import React from "react"
import Layout from "../../../components/layout"
import { Link } from 'gatsby';

const Covid = () => (
    <Layout>
    <section className="section-padding-large biq-bg-prim biq-text-white">
      <div className="container">
        <h1>Covid</h1>
      </div>
    </section>
    <section className="section-padding biq-bg-white biq-text-black select-none">
      <div className="container">
        <h2 className="text-left mt-6">Covid 19 Best Practices: Breathing</h2>
        <div className="flex biq-text-md" mt-6>
          The best way to have “strong lungs” is to strengthen the muscles that
          make them fill and empty—the 10 pounds of inhale and exhale muscles
          that include the diaphragm, intercostals, and core/ab muscles. Realize
          that your lungs don’t power themselves; you need to do breathing
          exercises to keep them healthy.
        </div>
        <h3 className="text-left mt-6">Why do breathing exercises?</h3>
        <div className="flex biq-text-md mt-6">
          <ol className="text-black">
            <li className="mt-4">
              Doing breathing exercises can help minimize the risk factors for
              COVID (the possibility of it going from mild to moderate or
              severe).
            </li>
            <li className="mt-4">Doing breathing exercises can help you recover from COVID.</li>
            <li className="mt-4">
              Doing breathing exercises can help manage the inflammation caused
              by stress and high cortisol.
            </li>
          </ol>
        </div>
        <h4 className="text-left mt-6">
          1. Doing breathing exercises can help minimize the risk factors for
          COVID.
        </h4>
        <div className="flex mt-6">
          One of the risk factors for COVID complications/severity is high blood
          pressure. There are numerous sound scientific studies that document
          that blood pressure can be lowered/controlled with daily breathing
          exercises.{' '}
        </div>
        <div className="flex mt-6">
          Having strong breathing is something you can measure and practice.
          Doing breathing exercises, apart from cardiovascular exercises, is
          vital, since “cardio” works your heart, not your breathing muscles as
          specifically or as hard as needed to strengthen them. Having “strong”
          breathing (capacity and velocity) is an important factor in
          longevity—especially since your breathing starts to decline after age
          29.
        </div>
        <div className="flex mt-6">
          People with respiratory illnesses like COPD (Chronic Obstructive
          Pulmonary Disease) are more at risk for COVID-19. COPD is the fourth
          leading cause of death in the US. Breathing exercises—particularly
          those that strengthen the exhale—are something that pulmonary rehab
          focuses on (to combat “overinflation,” the breathlessness and fatigue
          that accompanies it); however, you can do these on your own as part of
          prevention and good health practices to have a strong respiratory
          system.
        </div>
        <h4 className="text-left mt-6">2. Doing breathing exercises can help you recover from COVID.</h4>
        <div className="flex mt-6">
          Maintaining oxygen levels can be helped through mechanically sound
          breathing. Having a balance of strong inhales and exhales (oxygen and
          CO2) is what makes for good readings.{' '}
        </div>
        <div className="flex mt-6">
          If COVID progresses to pneumonia it’s critical to have an “efficient”
          cleansing cough. To cough efficiently, start by simply focusing on
          your exhale. Rather than just letting the exhale be a passive recoil,
          try narrowing both your belly and ribs as well (remember, the densest
          most oxygen-rich part of your lungs is in the middle of your body, not
          at the top).{' '}
        </div>
        <div className="flex mt-6">
          The feeling of tightness and psychological tension of being sick can
          cause you to “hover” in your breathing; that is to say, take only
          shallow sips in and out. This shallow breathing results in a vicious
          loop of stress and tension. While the easiest way to learn to breathe
          diaphragmatically is while you are on your back, breathing on your
          side does allow for the lateral expansion of the lower part of the
          lungs. Child’s Pose from yoga is the best posture in which to practice
          to “breathing through your back.”{' '}
        </div>
        <h4 className="text-left mt-6">
          3. Doing breathing exercises can help manage the inflammation caused
          by stress and high cortisol.
        </h4>
        <div className="flex mt-6">
          Breathing is the mind-body connection. If you are tense, it’s because
          your breathing is telling your mind that the environment is dangerous.
          Stress makes for a higher heart rate, higher cortisol, and a lower
          immune system.
        </div>
        <div className="flex mt-6">
          Stimulating the vagus nerve in order to achieve a more parasympathetic
          rest and digest state is achieved through the breath, both in the
          location of the breath and in the amount of expansion. An
          abdominothoracic breath (belly and ribs) that has good “Range of
          Motion” (wide inhale and narrow exhale) gives you more choices as to
          the pacing and slowing down of the breath.
        </div>
        <div className="flex mt-6">
          What to do? Practice ITS: Interrupt the Stress. Stress makes us brace,
          bracing makes us feel prepared. We humans are very resilient, but we
          need a reset from time to time. In order to reboot, unbrace your body
          and take a few belly breaths through your nose. This simple
          interruption of stress gets you out of the fight or flight for a few
          seconds, and this is what your body needs to reset and keep going.
        </div>
        <h3 className="text-left mt-6">Immediate Action Items</h3>
        <div className="flex biq-text-md mt-6">
          <ol>
            <li className="mt-4">
              Ensure sure you have a good mechanics by taking the Breathing IQ
              (www.thebreathingclass. com/biq). Interestingly enough, a more
              simple version of the BIQ that only considered chest expansion
              started in the late 1800s during the height of the another
              respiratory illness epidemic, tuberculosis. The BIQ combines type
              of breather (Horizontal, Hybrid, or Vertical) with
              abdominothoracic flexibility (Range of Motion). It is an easy,
              noninvasive functional breathing screening.{' '}
            </li>
            <li className="mt-4">
              A good breath is one where the body widens horizontally, then
              narrows on the exhale rather than stretching up and down or
              puffing up the chest (using “auxiliary” muscles). If you need an
              example, just look at your family cat or dog. Work on your ribcage
              flexibility (www.thebreathingclass.
              com/breathing-through-covid19). Next: strength. Once you have good
              mechanics, move on to strengthening your breathing muscles. (Some
              good news is that this diaphragmatic breathing can also alleviate
              digestive problems and back pain.)
            </li>
            <li className="mt-4">
              Practice breathing with your kids (see The Belly Breath,
              www.thebreathingclass.com/the-bellybreath). All the proceeds from
              this book go to COVID relief efforts.
            </li>
          </ol>
        </div>
        <h3 className="text-left mt-6">Notes:</h3>
        <div className="flex mt-6">
          If you have a neuromuscular disease like Parkinson’s, these exercises
          are imperative for you.
        </div>
        <div className="flex mt-6">
          These recommendations are not meant to diagnose or replace any advice
          from your physician, they are complimentary, and part of a growing
          database of instruction to help prevent and heal from respiratory
          illnesses.
        </div>
        <div className="flex mt-6">
          Dr. Belisa Vranich is a clinical psychologist and the author two books
          on breathing mechanics and psychology. She works with the New York
          City Fire Department’s Mental Performance Initiative, and was the
          former Director of Breathing Science at the Ash Center for
          Comprehensive Medicine in NYC.
        </div>
      </div>
    </section>
    <section
          id="biqMockup"
          className="section-padding biq-bg-gray"
          // style={{
          //   background: `url(${this.props.data.biqBackground.edges[0].node.childImageSharp.fluid.src})`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          //   backgroundBlendMode: 'darken',
          //   backgroundPositionY: '1',
          // }}
        >
          <div className="flex absolute">
            {/* <div className="w-full">
              <Img
                fluid={
                  this.props.data.biqBackground.edges[0].node.childImageSharp
                    .fluid
                }
              />
            </div> */}
          </div>
          <div className="container">
            {/* BIQ  */}
            <div className="flex flex-row flex-wrap my-24">
              {/* <div className="flex w-full xl:w-1/2"> */}
              {/* <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.biqMobile.edges[0].node.childImageSharp
                        .fluid
                    }
                  />
                </div> */}
              {/* </div> */}
              <div className="flex flex-col w-full place-items-center justify-center">
                <h1 className="uppercase biq-text-prim">Take the BIQ</h1>
                <h2 className="flex font-bold my-6 biq-text-xl text-center  biq-text-prim">
                  You can't change what you don't measure
                </h2>
                <span className="flex font-normal my-6">
                  <Link to="/biq" className="btn-prim biq-text-white">
                    Find Out Your BreathingIQ
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </section>
  </Layout>
)

export default Covid